/*eslint-disable*/
//Default
import React from 'react';
import { useState, useEffect } from 'react';

//Style
import { makeStyles } from '@material-ui/core/styles';
// import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";

//Group
import GridItem from '../../components/Grid/GridItem.js';
import GridContainer from '../../components/Grid/GridContainer.js';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Card from '../../components/Card/Card.js';
import CardHeader from '../../components/Card/CardHeader.js';
import CardBody from '../../components/Card/CardBody.js';
import CardFooter from '../../components/Card/CardFooter.js';

//Icon
import Accessibility from '@material-ui/icons/Accessibility';
import CardIcon from '../../components/Card/CardIcon.js';
import Warning from '@material-ui/icons/Warning';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SecurityUpdateWarningIcon from '@mui/icons-material/SecurityUpdateWarning';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import Checkbox from '@mui/material/Checkbox';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import SendIcon from '@mui/icons-material/Send';
import AddCircleIcon from '@mui/icons-material/AddCircle';
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

//Typography
import Danger from '../../components/Typography/Danger.js';
import Success from '../../components/Typography/Success.js';
import Primary from '../../components/Typography/Primary.js';
import Info from '../../components/Typography/Info.js';

//Component
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import AlertDialog from '../../components/AlertDlg/Alert.js';
import ProgressDlg from '../../components/AlertDlg/ProgressDlg.js';
import FeeInfo from '../../components/CreateToken/FeeInfo.js';
import CreateToken from '../../components/CreateToken/CreateToken.js';
import LoadTokens from '../../components/CreateToken/LoadTokens.js';

//Input
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CustomInput from '../../components/CustomInput/CustomInput.js';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

//Constant ABI
import { CREATE_TOKEN_ABI } from '../../Config/config.js';

//Constant Address
import { CREATE_TOKEN_MANAGE_ADDRESS } from '../../Config/config.js';

//Web3
//Web3 Interface
import Web3 from 'web3';
import { useWeb3React } from '@web3-react/core';
import { Contract, ethers, BigNumber as EthersBigNumber } from 'ethers';
import coinAddressValidator from 'coin-address-validator';
import { hexZeroPad } from '@ethersproject/bytes';
import BigNumber from 'bignumber.js';
import { utils } from 'ethers';

import { getDefaultProvider } from '../../components/WalletConnector.js';
import isValidAddress from '../../components/AddressValidator.js';

import ImgFooter from '../../assets/img/Mask group.png';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'PP Telegraf', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

const useStyles = makeStyles(styles);

let managecontractAddr = CREATE_TOKEN_MANAGE_ADDRESS.OnchainAI;

export default function CreateTokenMain() {
  const { account, library, chainId } = useWeb3React();

  //Alert Dlg
  const [dlgshow, setDlgshow] = useState(false);
  const [errorstr, setErrorStr] = useState('Error');

  //Progress Dlg
  const [progressdlg_flag, setProgressDlgFlag] = useState(false);
  const [progress_str, setProgressDlgStr] = useState('Starting');

  const [tokenList, setTokenList] = useState([]);

  const [coin, setCoin] = useState('');

  const [dashboard, setDashboard] = useState(false);

  const [outlinflag_create, setOutlineCreate] = useState('contained');
  const [outlinflag_dashboard, setOutlineDashboard] = useState('outlined');

  const classes = useStyles();

  const [feeInfo, setFeeInfo] = useState({
    normal: '000',
    mint: '000',
    burn: '000',
    pause: '000',
    blacklist: '000',
    deflation: '000',
    curfee: '000',
  });

  const ErrorDlgShow = (flag, alertstr) => {
    setErrorStr(alertstr);
    setDlgshow(flag);
  };

  const ProgressDlgShow = (flag, alertstr) => {
    setProgressDlgStr(alertstr);
    setProgressDlgFlag(flag);
  };

  const onClose = () => {
    ErrorDlgShow(false, '');
  };

  useEffect(() => {
    async function getFee() {
      await getFeeInfo();
    }

    setTimeout(() => {
      if (!library) {
        return;
      }
  
      if (!library._network) {
        return;
      }
  
      if (chainId === 33698) {
        setCoin('OCAI');
        managecontractAddr = CREATE_TOKEN_MANAGE_ADDRESS.OnchainAI;
      }
  
      if (account != undefined) {
        getFee();
      }
    }, 200);
    // getPrice();
  }, [account, library, chainId]);

  const connectContract = async (tokenabi, tokenaddr) => {
    let provider = await getDefaultProvider();
    let tempcontract;

    if (!library) {
      ErrorDlgShow(true, 'Wallet is unconnected');
      return;
    }

    if (!library._network) {
      ErrorDlgShow(true, 'Wallet is unconnected');
      return;
    }

    const isEthAddress = coinAddressValidator.validate(tokenaddr, 'eth', 'prod');

    if (!isEthAddress) {
      // setErrLabel("Token address is invalied");
      ErrorDlgShow(true, 'Address is invalid');
      return null;
    }

    try {
      tempcontract = new Contract(tokenaddr, tokenabi, provider);
    } catch (error) {
      // console.log(error)
      // setErrLabel("Contract connect error");
      return null;
    }

    return tempcontract;
  };

  const CreateManageContract = async () => {
    let manageContract;

    if (!library || !account) {
      ErrorDlgShow(true, 'Wallet is unconnected');
      return null;
    }

    manageContract = await connectContract(CREATE_TOKEN_ABI.manage, managecontractAddr);

    if (!manageContract) {
      ErrorDlgShow(true, 'Manage Contract connect error');
      ProgressDlgShow(false, '');
      return null;
    }

    let signer = await library.getSigner();

    if (signer) {
      try {
        manageContract = await manageContract.connect(signer);
        return manageContract;
      } catch (error) {
        ErrorDlgShow(true, 'Manage Contract signer connect error');
        ProgressDlgShow(false, '');
      }
    } else {
      return null;
    }
  };

  const createStandardToken = async (standard_info) => {
    await getFeeInfo();
    let manage_contract;

    if (!account) {
      ErrorDlgShow(true, 'Wallet is unconnected');
      ProgressDlgShow(false, '');
      return;
    }

    ProgressDlgShow(true, 'Creating');

    manage_contract = await CreateManageContract();

    if (!manage_contract) {
      ErrorDlgShow(true, 'Manage contract connect error');
      ProgressDlgShow(false, '');
      return;
    }

    let overrid = {
      value: ethers.utils.parseUnits(feeInfo.curfee, 18),
    };
    console.log('----------------------standard_info', standard_info);
    console.log('--------manage contract ', manage_contract);
    //Set Create Fee & Address
    try {
      //       function createStandard(
      //     address creator_,
      //     string memory name_,
      //     string memory symbol_,
      //     uint8 decimals_,
      //     uint256 tokenSupply_,
      //     SharedStructs.status memory _state
      // )
      //       struct status {
      //     uint256 mintflag;
      //     uint256 pauseflag;
      //     uint256 burnflag;
      //     uint256 blacklistflag;
      // }
      const status = {
        mintflag: parseInt(standard_info.mint),
        pauseflag: parseInt(standard_info.pause),
        burnflag: parseInt(standard_info.burn),
        blacklistflag: parseInt(standard_info.blacklist),
      };
      console.log('STATUSSSSSSSSSSSSSSSSSSS', status)

      await manage_contract.createStandard(
        standard_info.owner,
        standard_info.name,
        standard_info.symbol,
        parseInt(standard_info.decimal),
        ethers.utils.parseUnits(standard_info.totalsupply.toString()),
        status,
        overrid
      );

      await manage_contract.on('CreateStandardSuccess', (tokenaddr) => {
        // setProgressFlag(false);
        // getStandardTokenBalance(erctokenaddr);
        // console.log(tokenaddr);
        ErrorDlgShow(true, 'Your New Contract Address\n' + tokenaddr);
        ProgressDlgShow(false, '');
        // getInfo();
      });
    } catch (error) {
      console.log('Creatiing error', error);
      ErrorDlgShow(true, 'Create token Error');
      ProgressDlgShow(false, '');
      return;
    }
  };

  const createLiqudityToken = async (standard_info, deflation_info) => {
    await getFeeInfo();
    let manage_contract;
    console.log('----------------------standard_info', standard_info);

    if (!account) {
      ErrorDlgShow(true, 'Wallet is unconnected');
      ProgressDlgShow(false, '');
      return;
    }

    ProgressDlgShow(true, 'Creating');

    manage_contract = await CreateManageContract();

    if (!manage_contract) {
      ErrorDlgShow(true, 'Manage contract connect error');
      ProgressDlgShow(false, '');
      return;
    }

    let overrid = {
      value: ethers.utils.parseUnits(feeInfo.curfee, 18),
    };

    // console.log("overrid", overrid)
    // console.log("info", standard_info, deflation_info);
    // ProgressDlgShow(false, "");
    // return;
    //Set Create Fee & Address
    console.log('----------------------standard_info', standard_info);

    try {
      const status = {
        mintflag: parseInt(standard_info.mint),
        pauseflag: parseInt(standard_info.pause),
        burnflag: parseInt(standard_info.burn),
        blacklistflag: parseInt(standard_info.blacklist),
      };

      await manage_contract.createLiquidity(
        standard_info.owner,
        deflation_info.tax_address,
        standard_info.name,
        standard_info.symbol,
        parseInt(standard_info.decimal),
        ethers.utils.parseUnits(standard_info.totalsupply.toString()),
        parseInt(deflation_info.flag),
        [
          parseInt(deflation_info.tax_fee),
          parseInt(deflation_info.burn_fee),
          parseInt(deflation_info.hold_fee),
          parseInt(deflation_info.lp_fee),
        ],
        [
          parseInt(standard_info.mint),
          parseInt(standard_info.pause),
          parseInt(standard_info.burn),
          parseInt(standard_info.blacklist),
        ],
        overrid
      );

      await manage_contract.on('createLiquditySuccess', (tokenaddr) => {
        // setProgressFlag(false);
        // getStandardTokenBalance(erctokenaddr);
        // console.log(tokenaddr);
        ErrorDlgShow(true, 'Your New Contract Address\n' + tokenaddr);
        ProgressDlgShow(false, '');
        // getInfo();
      });
    } catch (error) {
      console.log('Creatiing error', error);
      ErrorDlgShow(true, 'Create token Error');
      ProgressDlgShow(false, '');
      return;
    }
  };

  const getFeeInfo = async () => {
    let manage_contract;
    console.log('--------------get fee info');
    if (!account) {
      ErrorDlgShow(true, 'Wallet is unconnected');
      ProgressDlgShow(false, '');
      return;
    }

    manage_contract = await CreateManageContract();

    if (!manage_contract) {
      ErrorDlgShow(true, 'Manage contract connect error');
      ProgressDlgShow(false, '');
      return;
    }

    let fee;

    try {
      console.log('--------------get manage_contract info', manage_contract);

      fee = await manage_contract.fee();
      console.log('fee', fee);
    } catch (error) {
      console.log(error);
      ErrorDlgShow(true, 'Get Fee Information Error');
      ProgressDlgShow(false, '');
      return;
    }
    console.log('-----------------------fee', fee);
    setFeeInfo((previousInputs) => ({
      ...previousInputs,
      normal: ethers.utils.formatUnits(fee.normal, 18),
      mint: ethers.utils.formatUnits(fee.mint, 18),
      burn: ethers.utils.formatUnits(fee.burn, 18),
      pause: ethers.utils.formatUnits(fee.pause, 18),
      blacklist: ethers.utils.formatUnits(fee.blacklist, 18),
      deflation: ethers.utils.formatUnits(fee.deflation, 18),
    }));

    // console.log("normal fee", feeInfo.curfee);
    if (feeInfo.curfee === '000') {
      setFeeInfo((previousInputs) => ({ ...previousInputs, curfee: ethers.utils.formatUnits(fee.normal, 18) }));
    }
  };

  const createStandardContract = async (addr) => {
    let tokenContract;

    if (!library || !account) {
      return null;
    }

    tokenContract = await connectContract(CREATE_TOKEN_ABI.standard, addr);

    if (!tokenContract) {
      return null;
    }

    let signer = await library.getSigner();

    if (signer) {
      try {
        tokenContract = await tokenContract.connect(signer);
      } catch (error) {
        console.log(error);
        return null;
      }
    } else {
      return null;
    }
    return tokenContract;
  };

  const createLiquidityContract = async (addr) => {
    let tokenContract;

    if (!library || !account) {
      return null;
    }

    tokenContract = await connectContract(CREATE_TOKEN_ABI.liquidity, addr);

    if (!tokenContract) {
      return null;
    }

    let signer = await library.getSigner();

    if (signer) {
      try {
        tokenContract = await tokenContract.connect(signer);
      } catch (error) {
        console.log(error);
        return null;
      }
    } else {
      return null;
    }
    return tokenContract;
  };

  const getTokenInfo = async (address) => {
    // debugger
    let tokencontract;
    if (!account) {
      ErrorDlgShow(true, 'Wallet is unconnected');
      ProgressDlgShow(false, '');
      return null;
    }

    tokencontract = await createStandardContract(address);

    if (!tokencontract) {
      ErrorDlgShow(true, 'token contract connect error');
      ProgressDlgShow(false, '');
      return null;
    }

    let name, symbol, supply, balance, decimal, type;
    let state;

    try {
      type = await tokencontract.isstandard();
      name = await tokencontract.name();
      decimal = await tokencontract.decimals();
      symbol = await tokencontract.symbol();
      supply = await tokencontract.totalSupply();
      balance = await tokencontract.balanceOf(account);
      // if(parseInt(type) === 1) {
      state = await tokencontract.state();
      // }
      // type = await tokencontract.isstandard();
      // console.log("info", name, decimal, symbol, supply, balance, state, type);
      return { address, name, symbol, decimal, supply, balance, type, state };
    } catch (error) {
      console.log(error);
      ErrorDlgShow(true, 'Get Token Information Error');
      ProgressDlgShow(false, '');
      return null;
    }
  };

  const getTokenAdresses = async (address) => {
    let manage_contract;

    if (!account) {
      ErrorDlgShow(true, 'Wallet is unconnected');
      ProgressDlgShow(false, '');
      return;
    }

    manage_contract = await CreateManageContract();

    if (!manage_contract) {
      ErrorDlgShow(true, 'manage contract connect error');
      ProgressDlgShow(false, '');
      return;
    }

    let tokenlists;

    try {
      tokenlists = await manage_contract.getCreatedToken(address);
      // console.log("tokens",tokens);
      // console.debug(123, tokenlists);
      setTokenList([]);
      let tokens = [];
      for (let i = 0; i < tokenlists.length; i++) {
        let token = await getTokenInfo(tokenlists[i]);
        if (token) {
          tokens.push(token);
        } else {
          return;
        }
      }
      setTokenList(tokens);
      ProgressDlgShow(false, '');
      // console.log(item);
    } catch (error) {
      console.log(error);
      ErrorDlgShow(true, 'Get Token Information Error');
      ProgressDlgShow(false, '');
      return;
    }
  };

  const getTokens = () => {
    ProgressDlgShow(true, 'Loading');
    getTokenAdresses(account);
  };

  const changeFeeInfo = (name, flag) => {
    let select_fee, prev_fee;
    if (feeInfo[name] === '000') {
      ErrorDlgShow(true, 'You should know ' + (name === 'deflation' ? 'tax' : name) + ' fee');
      return false;
    }
    select_fee = ethers.utils.parseUnits(feeInfo[name], 18);
    prev_fee = ethers.utils.parseUnits(feeInfo.curfee, 18);

    if (flag) {
      setFeeInfo({
        ...feeInfo,
        curfee: ethers.utils.formatUnits(prev_fee.add(select_fee)),
      });
    } else {
      setFeeInfo({
        ...feeInfo,
        curfee: ethers.utils.formatUnits(prev_fee.sub(select_fee)),
      });
    }

    return true;
  };

  const CreateButtonClick = () => {
    setOutlineCreate('contained');
    setOutlineDashboard('outlined');
    setDashboard(false);
  };

  const DashboardButtonClick = () => {
    setOutlineCreate('outlined');
    setOutlineDashboard('contained');
    setDashboard(true);
    getTokens();
  };

  return (
    <div>
      {/* <GridContainer>
        <GridItem xs={12} sm={12} md={2}></GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Info></Info>
        </GridItem>
      </GridContainer>
      <p /> */}
      <h1 className="text-5xl font-extrabold">Web3 AI Token Generator</h1>
      <div className="mt-[30px] flex gap-4 mb-[50px]">
        <button
          className={
            'text-[17px] font-extrabold text-white px-[35px] py-[13px] rounded-[10px] border' +
            (outlinflag_create == 'contained' ? ' bg-[#175ADC] border-[#175ADC]' : ' bg-transparent border-[#808080]')
          }
          onClick={() => CreateButtonClick()}
        >
          Create
        </button>
        <button
          className={
            'text-[17px] font-extrabold text-white px-[35px] py-[13px] rounded-[10px] border' +
            (outlinflag_dashboard == 'contained'
              ? ' bg-[#175ADC] border-[#175ADC]'
              : ' bg-transparent border-[#808080]')
          }
          onClick={() => DashboardButtonClick()}
        >
          Load
        </button>
      </div>
      <div className="grid grid-cols-12 md:gap-x-[20px] gap-y-[20px]">
        <div className={'col-span-12 bg-[#121212] rounded-[10px] p-[50px] ' + (!dashboard ? 'md:col-span-6' : '')}>
          {/* <div className="create-token-lefttop"></div> */}
          {!dashboard ? (
            <CreateToken
              createStandardToken={createStandardToken}
              createLiqudityToken={createLiqudityToken}
              changeFeeInfo={changeFeeInfo}
              user_addr={account}
            />
          ) : (
            <LoadTokens getTokens={getTokens} tokenListInfo={tokenList} />
          )}
        </div>
        {!dashboard && (
          <div className="md:col-span-6 col-span-12 bg-[#121212] rounded-[10px] p-[50px]">
            <FeeInfo fee={feeInfo} cur_coin={coin} getFeeInfo={getFeeInfo} />
          </div>
        )}
        <AlertDialog open={dlgshow} onClose={onClose} alerttext={errorstr} />
        <ProgressDlg open={progressdlg_flag} alerttext={progress_str} />
      </div>
      <Box className="mt-[100px] flex justify-center">
        <img src={ImgFooter} />
      </Box>
    </div>
  );
}